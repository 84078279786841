import React, {useEffect, useRef, useState} from "react";
import profileAvatar from "../../assets/images/avatarImg.svg";
import svg from "../../assets/images/Upload.svg";
import axios from "../../utils/axiosInstance";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
// import { useToasts } from "react-toast-notifications";
import {useCustomToast} from "../toast/useCustomToast";
import {CONTACT_DETAILS, GET_USER_INFO} from "../../utils/constants";

const ContactDetail = () => {
    const [userData, setUserData] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const fileInputRef = useRef('');
    const [fileUploaded, setFileUploaded] = useState(false);
    const [loading, setLoading] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [imageUpdate, setImageUpdate] = useState("");
    const [newImage, setNewImage] = useState(false);
    // const { addToast } = useToasts();
    const showToast = useCustomToast();


    const [payload, setPayload] = useState({
        email: "",
        password: {
            old_password: "",
            new_password: "",
            confirm_password: "",
        },
        image: "",
    });
    const [docUploadPayload, setDocUploadPayload] = useState("");

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleToggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (!file) {
            showToast("Please select an image.", "error");
            return;
        }
        if (file.size > 3 * 1024 * 1024) {
            showToast("Image size should not exceed 3MB", "error");
            setSelectedImage(""); // Clear the selected file
        } else {

            setNewImage(true);
            setSelectedImage(file);
        }
    };


    const handleUpload = () => {
        // Perform upload logic
        // Set uploadProgress based on the actual progress
        // This is just a placeholder, replace it with your actual upload logic
        setUploadProgress(100);
        setFileUploaded(true);
        setTimeout(() => {
            setFileUploaded(false);
        }, 2000);
    };

    const handleUploadButtonClick = () => {
        // Trigger click on the hidden file input
        fileInputRef.current.click();
    };

    const handleSaveChanges = async () => {
        try {
            setLoading(true);

            // Check if the image size is more than 3MB
            if (selectedImage && selectedImage.size > 3 * 1024 * 1024) {
                showToast("Image size should not exceed 3MB", 'error');
                return; // Stop further processing
            }

            const contactUpdate = new FormData();
            contactUpdate.append('email', userData.email || "");
            contactUpdate.append('image', selectedImage || "");
            contactUpdate.append('old_password', payload.password.old_password || "");
            contactUpdate.append('new_password', payload.password.new_password || "");
            contactUpdate.append('confirm_password', payload.password.new_password || "");


            // Send a request to update the user profile
            await axios.post(CONTACT_DETAILS, contactUpdate);
            // Display a success toast notification
            showToast("Profile updated successfully!", 'success');
        } catch (error) {

            if (error.response) {
                const {status, message, errors} = error.response.data;
                if (status === "Error") {
                    if (message && errors.length === 0) {
                        showToast(message, 'error');
                    } else if (message && errors.length > 0) {
                        showToast(errors[0], 'error');
                    }
                }
            }
        } finally {
            setLoading(false); // Set loading to false regardless of success or error
        }
    };

    useEffect(() => {
        // Flag to track if the component is mounted

        axios.get(GET_USER_INFO)
            .then((response) => {
                setUserData(response.data.data.agent);
                setPayload({
                    ...payload,
                    email: response.data.data.agent.email,
                });
                // Set selected image URL if available in user data
                setNewImage(false);
                setSelectedImage(response.data.data.image);

            })
            .catch((error) => {
                if (error.response) {
                    const {status, message, errors} = error.response.data;
                    if (status === "Error") {
                        if (message && errors.length === 0) {
                            showToast(message, 'error');
                        } else if (message && errors.length > 0) {
                            showToast(errors[0], 'error');
                        }
                    }
                }

            });

        // Cleanup function to cancel any ongoing tasks when component unmounts

    }, []);


    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await axios.post(CONTACT_DETAILS);
                const userProfileData = response.data;

                // setEmail(userProfileData.email || '');
                // setPassword(userProfileData.password || '');
                // setConfirmPassword(userProfileData.password || '');
            } catch (error) {

                if (error.response) {
                    const {status, message, errors} = error.response.data;
                    if (status === "Error") {
                        if (message && errors.length === 0) {
                            showToast(message, 'error');
                        } else if (message && errors.length > 0) {
                            showToast(errors[0], 'error');
                        }
                    }
                }
            }
        };

        fetchUserProfile();
    }, []);

    return (
        <div className="md:p-20 p-5 text-2xl" id="contact">
            {/* Replace the following comment with your actual contact details form */}

            <form className="flex flex-col">
                <div className="flex gap-10 items-center my-8 justify-center mx-auto">
                    {/* Display the selected image (if any) */}
                    <div className="flex flex-col">
                        {/* {selectedImage && (
                  <img
                    src={}
                    alt="Selected"
                    className="mb-4 rounded-lg"
                    style={{ maxWidth: "300px", maxHeight: "300px" }}
                  />
                )} */}

                        {/* Image upload input */}
                        <label htmlFor="imageInput" className="mb-2">
                            Image:
                        </label>

                        <div className="flex flex-col border border-gray-100 items-center p-6">
                            <input
                                type="file"
                                id="imageInput"
                                name="image"
                                accept=".pdf, .jpg, .png"
                                onChange={handleImageChange}
                                className=" mb-4"
                                ref={fileInputRef} // Reference to the hidden file input
                                style={{display: "none"}}
                            />
                            <div className="h-20 w-20 overflow-hidden rounded-full">
                                <img
                                    alt=""
                                    src={
                                        (selectedImage && newImage)
                                            ? URL.createObjectURL(selectedImage)
                                            : (selectedImage && !newImage) ? selectedImage : profileAvatar
                                    }
                                    className="w-full h-full"
                                />
                            </div>

                            <button
                                type="button"
                                className="bg-white text-white px-4 py-2 rounded"
                                onClick={handleUploadButtonClick}
                            >
                                <img src={svg} alt=""/>
                            </button>
                            <p className="w-1/2 text-center">
                                Click to upload or drag and drop SVG, PNG, JPG (max,
                                3mb)
                            </p>
                        </div>
                    </div>

                    {/* Additional form elements or buttons can be added here */}

                    <div className="flex gap-10 items-center my-8"></div>
                </div>
                <div className="flex gap-10 items-center my-8">
                    <div className="flex flex-col text-xl">
                        <label htmlFor="phone number" className="my-3">
                            Phone Number:
                        </label>
                        <input
                            type="number"
                            placeholder="Type..."
                            id="phone-number"
                            name="phone number"
                            value={userData ? userData.business_phone : ""}
                            className="outline outline-gray-100 py-3 px-3 md:w-[300px] lg:w-[500px] w-full"
                            disabled
                        />
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="email" className="my-3">
                            Email:
                        </label>
                        <input
                            type="email"
                            placeholder="Type..."
                            id="email"
                            name="email"
                            className="outline outline-gray-100 py-3 px-1 md:w-[300px] lg:w-[500px] w-full"
                            value={userData ? userData.email : ""}
                        />
                    </div>
                </div>
                <div className="flex gap-10 items-center my-8">
                    <div className="flex flex-col">
                        <label htmlFor="password" className="my-3">
                            Old Password:
                        </label>
                        <div className="relative">
                            <input
                                type={showPassword ? "text" : "password"}
                                id="password"
                                name="password"
                                value={payload.password.old_password}
                                onChange={(e) =>
                                    setPayload({
                                        ...payload,
                                        password: {
                                            ...payload.password,
                                            old_password: e.target.value,
                                        },
                                    })
                                }
                                placeholder="***********"
                                className="outline outline-gray-100 py-3 px-3 md:w-[300px] lg:w-[500px] w-full pr-12" // Adjust paddingRight to accommodate the icon
                                required
                            />
                            <button
                                type="button"
                                onClick={handleTogglePasswordVisibility}
                                className="password-toggle absolute inset-y-0 right-0 flex items-center cursor-pointer"
                            >
                                <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash}/>
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-col ">
                        <label htmlFor="confirmPassword" className="my-3">
                            New Password:
                        </label>
                        <div className="password-input relative">
                            <input
                                type={showConfirmPassword ? "text" : "password"}
                                id="confirmPassword"
                                name="confirmPassword"
                                value={payload.password.new_password}
                                onChange={(e) =>
                                    setPayload({
                                        ...payload,
                                        password: {
                                            ...payload.password,
                                            new_password: e.target.value,
                                            confirm_password: e.target.value,
                                        },
                                    })
                                }
                                placeholder="***********"
                                className="outline outline-gray-100 py-3 px-3 md:w-[300px] lg:w-[500px] w-full pr-12" // Adjust paddingRight to accommodate the icon
                                required
                            />
                            <button
                                type="button"
                                onClick={handleToggleConfirmPasswordVisibility}
                                className="password-toggle absolute inset-y-0 right-0 flex items-center cursor-pointer"
                            >
                                <FontAwesomeIcon
                                    icon={showConfirmPassword ? faEye : faEyeSlash}
                                />
                            </button>
                        </div>
                    </div>
                </div>


                <button
                    type="submit"
                    onClick={handleSaveChanges}
                    className={`bg-color1  rounded-lg h-14 w-full text-white mx-auto relative ${loading ? 'opacity-50 pointer-events-none' : ''
                    }`}
                    disabled={loading}
                >
                    {loading && (
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <div className="loader"></div>
                        </div>
                    )}
                    {loading ? 'Saving...' : 'Save'}
                </button>
            </form>
        </div>
    )
}

export default ContactDetail
