import React, {useEffect} from "react";
import {connect} from "react-redux";

import {setDisplayModal} from "../actions/modal";

import {setCurrentPage} from "../actions/page";

import gotv from "../assets/icons/gotv.svg";
import dstv from "../assets/icons/dstv.svg";
import startimes from "../assets/icons/startimes.svg";

import styles from "./BillPayment.module.scss";

export const BillPayment = ({changeCurrentPage, displayModal}) => {
    useEffect(() => {
        changeCurrentPage({
            heading: "Bill Payment",
            search: true,
        });
    }, [changeCurrentPage]);

    return (
        <div className={styles.container}>
            <div className={styles.section}>
                <div className={styles.card}>
                    <h3 className={styles.sectionHeading}>Cable TV</h3>
                    <div className={styles.services}>
                        <div
                            className={styles.service}
                            onClick={() => {
                                displayModal({
                                    overlay: true,
                                    modal: "cableRecharge",
                                    service: "dstv",
                                });
                            }}
                        >
                            <img className={styles.serviceLogo} src={dstv} alt=""/>
                            <p className={styles.serviceText}>DSTV</p>
                        </div>
                        <div
                            className={styles.service}
                            onClick={() => {
                                displayModal({
                                    overlay: true,
                                    modal: "cableRecharge",
                                    service: "gotv",
                                });
                            }}
                        >
                            <img className={styles.serviceLogo} src={gotv} alt=""/>
                            <p className={styles.serviceText}>GOTV</p>
                        </div>
                        <div
                            className={styles.service}
                            onClick={() => {
                                displayModal({
                                    overlay: true,
                                    modal: "cableRecharge",
                                    service: "startimes",
                                });
                            }}
                        >
                            <img className={styles.serviceLogo} src={startimes} alt=""/>
                            <p className={styles.serviceText}>Startimes</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeCurrentPage: (payload) => dispatch(setCurrentPage(payload)),
        displayModal: (payload) => dispatch(setDisplayModal(payload)),
    };
};

export default connect(undefined, mapDispatchToProps)(BillPayment);
