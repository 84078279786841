import React, {useEffect, useState} from 'react';
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import axios from '../../../utils/axiosInstance';
import {useSelector} from 'react-redux';
import {useCustomToast} from '../../../components/toast/useCustomToast';

const ChartChad = ({period, onLoadingChange}) => {
    const [chartData, setChartData] = useState([]);
    const [loading, setLoading] = useState(false);
    const agentId = useSelector((state) => state.auth.user?.id);
    const showToast = useCustomToast();


    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`/tranxanalysis?period=${period}&agent_id=${agentId}`);
                const {status, data} = response.data;

                if (status === 'Successful') {
                    // Format data based on period
                    const formattedData =
                        period === 'weekly'
                            ? data.days.map((day, index) => ({name: day, pv: data.amount[index]}))
                            : period === 'monthly'
                                ? data.month.map((month, index) => ({name: month, pv: parseFloat(data.amount[index])}))
                                : period === 'yearly'
                                    ? data.year.map((year, index) => ({
                                        name: String(year),
                                        pv: parseFloat(data.amount[index])
                                    }))
                                    : [];

                    setChartData(formattedData);
                }
            } catch (error) {

                if (error.response) {
                    const {status, message, errors} = error.response.data;
                    if (status === "Error") {
                        if (message && errors.length === 0) {
                            showToast(message, 'error');
                        } else if (message && errors.length > 0) {
                            showToast(errors[0], 'error');
                        }
                    }
                }
            } finally {
                setLoading(false);
                // Notify the parent component about the loading state change
                onLoadingChange && onLoadingChange(loading);
            }
        };

        fetchData();
    }, [period, onLoadingChange]);

    return (
        <ResponsiveContainer width="100%" height={300}>
            <BarChart
                width={500}
                height={300}
                data={chartData}
                margin={{
                    top: 5,
                    right: 10,
                    left: 10,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name"/>
                <YAxis/>
                <Tooltip/>
                <Legend/>
                <Bar dataKey="pv" fill="#3E215B"/>
            </BarChart>
        </ResponsiveContainer>
    );
};

export default ChartChad;
