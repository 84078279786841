import React from 'react';
import {connect} from 'react-redux';
import BarChart from '../../features/dashboard/BarChart';
import PieChart from '../../features/dashboard/PieChart';
import Trans from '../../features/dashboard/TransactionHistory';
import Balance from '../../features/dashboard/Balance/Balance';
import QuickAction from '../../features/dashboard/QuickAction';
import {setDisplayModal} from '../../actions/modal';
import {setCurrentPage} from '../../actions/page';
import UserInfo from '../../features/dashboard/userInfo';

import styles from './Overview.module.scss';

export const Overview = ({
                             displayModal
                         }) => {
    return (
        <div className={styles.container}>
            <UserInfo/>
            <Balance/>
            <QuickAction displayModal={displayModal}/>
            <div className="flex md:gap-10 md:flex-col lg:flex-row flex-col">
                <BarChart/>
                <PieChart/>
            </div>
            <Trans/>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeCurrentPage: (payload) => dispatch(setCurrentPage(payload)),
        displayModal: (payload) => dispatch(setDisplayModal(payload)),
    };
};

export default connect(undefined, mapDispatchToProps)(Overview);
