import React, {useReducer, useState} from 'react';
import axios from '../../../utils/axiosInstance';
import {connect} from 'react-redux';
import {useCustomToast} from '../../../components/toast/useCustomToast';

import {setCurrentPage} from '../../../actions/page';
import {VEND_DATA} from '../../../utils/constants';
import DataPurchaseReducer, {initialState} from './data-reducer';
import BuyDataForm from './BuyDataForm';
import BuyDataSummary from './BuyDataSummary';
import BuyDataStatus from './BuyDataCompleted';
import FailedTransaction from '../../../components/common/FailedTransaction';
import {EventEmitter} from '../../../utils/event';
// import styles from "./BuyData.module.scss";

export const BuyData = ({service, hasSetPin}) => {
    let renderedComponent;
    const TRANSACTION_COST = 0;
    const [componentToRender, setComponentToRender] = useState('form');
    const [DataPurchaseFormState, dispatch] = useReducer(
        DataPurchaseReducer,
        initialState
    );
    const [successData, setSuccessData] = useState(null);
    const [loading, setLoading] = useState(false);
    const showToast = useCustomToast();
    const [failedErrorMessage, setFailedErrorMessage] = useState('');

    const handleOnSubmit = () => {
        const {amount, phone, operator, productId, transaction_pin} = DataPurchaseFormState;
        var newPhone = phone;
        var operators = operator;


        setLoading(true);

        if (phone.indexOf('+234') === 0) {
            newPhone = phone.replace('+234', '');
        }

        if (phone.indexOf('234') === 0) {
            newPhone = phone.replace('234', '');
        }

        if (phone.indexOf('0') === 0) {
            newPhone = phone.replace('0', '');
        }
        if (operators === 'mtn') {
            operators = 'MTN'
        }
        if (operators === 'airtel') {
            operators = 'Airtel'
        }
        if (operators === 'glo') {
            operators = 'Globacom'
        }
        if (operators === '9mobile') {
            operators = '9mobile'
        }

        const payload = {
            amount,
            productId,
            transaction_pin,
            recipient: `234${newPhone}`,
            operator: `${operators}`

        };

        axios
            .post(VEND_DATA, payload, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
            .then((res) => {
                const successData = res.data.data;
                const message = res.data.message;

                const date = new Date();

                setLoading(false);
                setSuccessData({...successData, date: date.toDateString()});
                showToast(message, 'success');
                setComponentToRender('success');
                EventEmitter.dispatch('refresh-wallet-balance', {});
            })
            .catch((err) => {
                if (err.response && err.response.status === 403) {
                    setLoading(false);
                    showToast(err.response.data.message, 'error');
                    setFailedErrorMessage(err.response.data.message || undefined);
                    setComponentToRender('failed');
                } else if (err.response && err.response.status === 400) {
                    setLoading(false);
                    showToast(err.response.data.message, 'error');
                    setFailedErrorMessage(err.response.data.message || undefined);
                    setComponentToRender('failed');
                } else if (err.response && err.response.status === 401) {
                    setLoading(false);
                    showToast(err.response.data.message, 'error');
                    setFailedErrorMessage(err.response.data.message || undefined);
                    setComponentToRender('failed');
                } else {
                    setTimeout(() => {
                        setLoading(false);
                        setComponentToRender('failed');
                        setFailedErrorMessage(err.response.data.message || undefined);
                    }, 3000);
                }
            });
    };

    switch (componentToRender) {
        case 'form':
            renderedComponent = (
                <BuyDataForm
                    DataPurchaseFormState={DataPurchaseFormState}
                    setState={dispatch}
                    setComponentToRender={setComponentToRender}
                    service={service}
                />
            );
            break;
        case 'summary':
            renderedComponent = (
                <BuyDataSummary
                    DataPurchaseFormState={DataPurchaseFormState}
                    handleOnSubmit={handleOnSubmit}
                    loading={loading}
                    dispatch={dispatch}
                    transactionCost={TRANSACTION_COST}
                    service={service}
                    hasSetPin={hasSetPin}
                />
            );
            break;
        case 'success':
            renderedComponent = (
                <BuyDataStatus
                    successData={successData}
                    transactionCost={TRANSACTION_COST}
                    setComponentToRender={setComponentToRender}
                    DataPurchaseFormState={DataPurchaseFormState}
                    service={service}
                />
            );
            break;
        case 'failed':
            renderedComponent = <FailedTransaction message={failedErrorMessage}/>;
            break;
        default:
            renderedComponent = null;
            break;
    }

    return <div>{renderedComponent}</div>;
};

const mapStateToProps = (state) => {
    return {
        service: state.modal.service,
        hasSetPin: state.auth.has_pin,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeCurrentPage: (payload) => dispatch(setCurrentPage(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyData);
